
.link1{
  color: white;
  text-decoration: none;
}

.link2{
  color: black;
  text-decoration: none;
}

.main_module_link{
  text-decoration: none;
}
.section2_row1_img img {
    width: 100%;
    height: 300px;
    padding: 0px !important;
    margin: 0px !important;
    object-fit: auto;
  }
  
  .section1_nav ul li a {
    color: white !important;
    font-weight: bold;
    text-decoration: none;
  }
  
  .section1_nav ul li a:hover {
    color: orange !important;
    font-weight: bold;
  }
  .firstheading{
     font-size: 3vw;
     font-weight:bold;
  }
  .homeshop:hover {
    box-shadow: 1px 1px 9px 1px red;
    color: red;
  }
  .homechef:hover {
    box-shadow: 1px 1px 9px 1px green;
    color: green;
  }
  
  .shopping:hover {
    box-shadow: 1px 1px 9px 1px skyblue;
    color: skyblue;
  }
  .restra:hover {
    box-shadow: 1px 1px 9px 1px pink;
    color: pink;
  }
  
  .supl:hover {
    box-shadow: 1px 1px 9px 1px purple;
    color: purple;
  }
  .serve:hover {
    box-shadow: 1px 1px 9px 1px blue;
    color: blue;
  }
  
  .taxi:hover {
    box-shadow: 1px 1px 9px 1px orangered;
    color: orangered;
  }
  
  .jobs:hover {
    box-shadow: 1px 1px 9px 1px lightgreen ;
    color: lightgreen;
  }
  .section3_row1 img {
    width: 100%;
    height: auto;
    box-shadow: 1px 1px 7px 1px;
  }
  .section3_row1 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .section5_container {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
  
  .section5_container p {
    font-weight: bold;
    font-size: 25px;
  }
  .section5_row1 ul li {
    list-style-type: none;
    text-align: justify;
  }
  .section6_row1_col1 .p1 {
    font-size: 30px;
    font-weight: bold;
  }
  .section6_row1_col1 .p2 {
    text-align: justify;
  }
  .section6_row1_col1 .a1 {
    padding: 15px 70px 15px 70px;
    font-weight: bold;
    font-size: 18px;
  }
  .section6_row1_col1 {
    padding-top: 90px;
  }
  .section6 {
    padding-top: 40px;
  }
  
  /* section 7 start*/
  .bgimg {
    /* background-image: url("../images/homeshop2.png"); */
  
    height: 500px;
    background-repeat: no-repeat;
    background-size: center;
    background-position: center;
    object-fit: contain;
  }
  
  .section7_container .p1 {
    font-size: 30px;
    font-weight: bold;
  }
  .section7_container a {
    padding: 20px 40px 20px 40px;
    font-weight: bold;
    font-size: 18px;
  }
  
  /* section 7 end*/
  .footer_row1 {
    background-color: #17a2b8;
    padding: 10px;
  }
  .footer_row1 a {
    color: white;
    text-decoration: none;
  }
  
  .footer_row2 ul li {
    list-style-type: none;
    line-height: 2.7;
  }
  .footer_row2 ul li a {
    color: black;
    text-decoration: none;
  }
  .footer_row2 ul li a:hover {
    text-decoration: none;
    color: orange;
  }
  .footer_row2 p {
    font-weight: bold;
  }
  .footer_row1_col1 i {
    color: white;
  }
  
  .footer_row3 i {
    font-size: 35px;
  }
  .footer_row3 p {
    font-size: 18px;
    font-weight: bold;
    line-height: 0;
  }
  .footer_row3 span {
    font-size: 12px;
    line-height: 0.3;
  }
  /* .footer_row3 {
    background-color: #5c366f;
    padding: 30px;
  } */
  .footer_row3 a i {
    color: black;
    font-size: 25px;
  }
  .footer_row3_col4 a {
    border: 1px solid white;
  
    padding: 15px 5px 15px 5px;
    /* color: white; */
    font-size: 12px;
    font-weight: bold;
  }
  
  /* Section Taxi Booking Start */
  
  .section4 img {
    width: 100%;
    height: 300px;
  }
  .section4_row1 a {
    color: rgb(167, 38, 167);
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  .section4_row1 {
    background-color: #5c366f;
    padding: 20px 0px 20px 0px;
  }
  .section4_row1 a:hover {
    color: rgb(235, 36, 235);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
  }
  
  .section4_row2_col1 .p1 {
    color: purple;
    font-size: 18px;
  }
  
  .section4_row2_col1 span {
    color: rgb(62, 160, 62);
    font-size: 18px;
  }
  
  
  .section4_row2_col1 {
    box-shadow: 1px 1px 9px 1px;
  }
  /* Section Taxi Booking Start */
  
  
  /* Services Page Css Start */
  
  .services_section1_bgimg{
      /* background-image: url("../images/homeshop.png"); */
  
      height: 30vw;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      justify-content: center;
     align-items: center;
     background-attachment: fixed;
  }
  
  
  .cardinner{
    background-color: white;
    margin-top: 60px;
    padding: 3vw 3vw 5vw 3vw;
    box-shadow: 1px 1px 9px 1px;
  }
  .cardinner .p1{
    font-size: 2.8vw;
    font-weight: bold;
  }
  .search_inputs{
    font-size: 1.3vw;
  }
  
  
  .services_section1_div1 p{
   font-size: 40px;
   font-weight: bold;
     
     }
  
    
    .sticky-sidebar {
      position: sticky;
      top: 50px; /* Adjust the top value based on your needs */
      height: 100vh; /* Set the height of the sidebar */
      overflow-y: clip; /* Enable vertical scrolling */
    }
    
  
    
  .services_section2_row1_col1 p{
      font-size: 25px;
      font-weight: bold;
  }
  
  .services_section2_row1_col1 ul{
     list-style-type: none;
  }
  .services_section2_row1_col1 ul li a{
      color: black;
   }
   .services_section2_row1_col1 ul li a:hover{
      color: red;
      text-decoration: none;
   }
  
   .services_section2_row1_col1 ul li{
      line-height: 50px;
   }
  
  .services_cards_title h5{
    font-size: 16px !important;
    font-weight: bold;
    color: #767676;
  }
  .services_row1_ul ul{
    list-style-type: none;
  }
  .services_row1_ul ul li{
    line-height: 35px;
  }
  .services_row1_ul ul li a {
  color: #767676;
  
  }
  
  .services_row1_ul ul li a:hover{
  text-decoration: none;
  color: orangered;
  }
  
  
  /* Services Page Css End */
  
  /* Taxi Page css start */
  
  .taxi_section1_row2_col1 .p1{
  font-size: 30px;
  font-weight: bold;
  padding-top: 90px;
  text-align: justify;
  }
  .taxi_section1_row2_col1 .p2{
    font-size: 22px;
  
    text-align: justify;
    }
  
    .taxi_section1_row2_col1 a{
      font-size: 20px;
      margin-top: 10px;
      padding: 10px 20px 10px 20px;
      }
  .taxi_section2_container .p1{
    padding-top: 90px;
    font-size: 40px;
    font-weight: bold;
  }
  .taxi_section2_row1_col2>i{
  color: black;
  font-size: 22px;
  padding-bottom: 20px;
  text-align: center;
  }
  .taxi_section2_row1_col2 .p2{
   
    font-weight: bold;
    font-size: 22px;
    }
    .taxi_section2_row1_col2 .p3{
   color: gray;
     
      font-size: 18px;
      }
  
  /* section3 start */
  .section3_bg_img{
  /* background-image: url("../images/cityview3.jpg"); */
  height: 30vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  justify-content: center;
  align-items: center;
  /* background-attachment: fixed; */
  }
  
  .road {
  
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  
  .car {
    width: 100%;
    height: 150px;
    background-image: url('./images/taxibenifits.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
   top: 30px;
    animation: moveCar 5s linear infinite;
  }
  
  @keyframes moveCar {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  
  .taxi_cardinner{
    /* background-color: white; */
    margin-top: 30px;
    padding: 3vw 3vw 5vw 3vw;
    /* box-shadow: 1px 1px 9px 1px; */
  }
  .taxi_cardinner .p1{
    font-size: 2vw;
    font-weight: bold;
  }
  .taxi_cardinner .p2{
    font-size: 1.2vw;
    font-weight: bold;
  }
  .getstarted{
    margin-top: 30px;
    padding: 10px 30px 10px 30px;
  }
  /* section3 end */
  
  /* section4 start */
  .section4_container .p1{
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
  }
  
  .section4_container .p2{
    font-size: 22px;
    font-weight: bold;
    /* padding-top: 60px; */
    }
  /* section4 end */
  
  /* section5 start */
  
  .section5_row1_col2 .p1{
  font-size: 3vw;
  font-weight: bold;
  }
  .section5_row1_col2 .p2{
    font-size: 1.3vw;
    text-align: justify;
  
    }
    .section5_row1_col2 .viewride{
      padding: 10px 5px 10px 5px;
    
      }
  .taxi_section5_container{
    padding: 90px 0px 90px 0px;
  }
  /* section5 end */
  /* Taxi Page css end */
  /* job page css start */
  
  .job_container{
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 20px 20px 20px 20px;
    border-radius: 20px;
  }
  
  .job_container .p1{
  font-size: 2.8vw;
  font-weight: bold;
  
  }
  
  .job_container .p2{
    font-size: 18px;
    text-align: justify;
   
    }
  
  .job_section3 .p1{
    font-size: 30px;
    font-weight: bold;
  }
  
  /* job page css end */
  
  /* Shopping Page Start */
  .shop_section2_row1_col2 img{
  width: 100%;
  height: 180px;
  
  }
  .shop_section2_row1_col1 p{
    font-size: 30px;
   padding-top: 40px;
   font-weight: bold;
  }
  .shop_section2_row1_col2 .p1{
  font-weight: bold;
    }
    .shop_section2_row1_col2 .p2{
      color: green;
        }
        .shop_section2_row1_col2 .p3{
          color: gray;
            }
      
  .shop_section3_container .p4{
    font-size: 30px;
    font-weight: bold;
  background-color: green;
  padding: 40px 0px 40px 0px;
  text-align: center;
  color: white;
  }
  /* Shopping Page Css end */
  
  /* Supplies Page Start */
  
  
      
  /* Supplies Page End */
  
  
  /* Media Start */
  @media screen and (min-width: 200px) and (max-width: 400px) {
    .section4_row1 a {
      color: rgb(167, 38, 167);
      font-size: 10px;
      font-weight: bold;
    }
  }
  @media screen and (min-width:200px) and (max-width:800px){
      /* .firstheading{
          font-size: 20px !important;
      } */
  }
  @media screen and (min-width:200px) and (max-width:500px){
      .footer_row3{
          text-align: center;
      }
      .callus{
          padding-top: 10px;
      }
  
  }
  @media screen and (min-width:200px) and (max-width:1000px){
    .services_section1_bgimg{
  height: 50vw;
    }
  }
  @media screen and (min-width:200px) and (max-width:600px){
    .bottom_footer{
      margin-top: 30px;
    }
  }
  
  
  /* Media End */
  